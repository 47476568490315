$biojars-gold: #b08936;

$popover-header-bg: $biojars-gold;
$popover-arrow-color: $biojars-gold;

$component-active-color: #FFF;
$component-active-bg: $biojars-gold;

//$btn-border-radius: 1.5rem;
//$btn-border-radius-sm: 1.4rem;
//$btn-border-radius-lg: 2.0rem;

$btn-border-radius: 0.5rem;
$btn-border-radius-sm: 1.4rem;
$btn-border-radius-lg: 0.5rem;

$ng-select-highlight: $biojars-gold;
