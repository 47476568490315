
.bio .sprite {
  position: relative;
  width: 50px;
  height: 50px;
  margin: auto;
  background-position: 0 0;
  background-repeat: no-repeat;
}

.bio .sprite-home {
  background-image: url('/assets/img/ico-home-sprite.png');
}

.bio .sprite-bios {
  background-image: url('/assets/img/ico-bios-sprite.png');
}
.bio .sprite-tribe {
  background-image: url('/assets/img/ico-mytribe-sprite.png');
}
.bio .sprite-life-wall {
  background-image: url('/assets/img/ico-lifewall-sprite.png');
}
.bio .sprite-wards {
  background-image: url('/assets/img/ico-wards-sprite.png');
}
.bio .sprite-guardians {
  background-image: url('/assets/img/ico-guardians-sprite.png');
}
.bio .sprite-jars {
  background-image: url('/assets/img/ico-jars-sprite.png');
}

.bio .sprite-jars-memories {
  background-image: url('/assets/img/ico-memoryjars-sprite.png');
}

.bio .sprite-jars-things {
  background-image: url('/assets/img/ico-thingsjars-sprite.png');
}

.bio .sprite-jars-passwords {
  background-image: url('/assets/img/ico-password-sprite.png');
}

.bio .sprite-wishes {
  background-image: url('/assets/img/ico-wishes-sprite.png');
}
.bio .sprite-wisdom {
  background-image: url('/assets/img/ico-wisdom-sprite.png');
}

.bio .sprite-support {
  background-image: url('/assets/img/ico-support-sprite.png');
}

.bio .sprite-faqs {
  background-image: url('/assets/img/ico-faqs-sprite.png');
}

.bio .sprite-knowledge {
  background-image: url('/assets/img/ico-knowledge-sprite.png');
}

.bio .sprite-tutorials {
  background-image: url('/assets/img/ico-tutorials-sprite.png');
}

.bio .sprite-settings {
  background-image: url('/assets/img/ico-settings-sprite.png');
}

.bio .sprite-setting {
  background-image: url('/assets/img/ico-setting-sprite.png');
}

.bio .sprite-billing {
  background-image: url('/assets/img/ico-billing-sprite.png');
}

.bio .sprite-account-change {
  background-image: url('/assets/img/ico-change-account-sprite.png');
}

.bio .sprite-account {
  background-image: url('/assets/img/ico-account-sprite.png');
}

.bio .sprite-features {
  background-image: url('/assets/img/ico-features-sprite.png');
}

.bio .active .sprite {
  background-position: 0 -50px;
}

.bio .sprite-thing-jar {
  width: 109px;
  height: 124px;
  background-image: url('/assets/img/biojars-ico-thing-jar.png');
  background-position: 0 -124px;
}



.bio .active .sprite-thing-jar {
  background-position: 0 0;
}
