
.bio .memory-jar .file-uploader-list-item-inset,
.bio .memory-jar .file-uploader-list-item-preview,
.bio .thing-preview .file-uploader-list-item-inset,
.bio .thing-preview .file-uploader-list-item-preview {
  height: auto;
}

.bio .memory-jar .file-uploader-list {
  text-align: left;
}

.bio .memory-jar .file-uploader-list-item {
  width: 33.333%;
  padding: 6px;
}

.bio .file-uploader-list-item:first-child .file-uploader-list-item-preview {
  box-shadow: 0 0 3px 0 $biojars-gold;
}

.bio .img-lazy-loader .btn-download {
  position: absolute;
  min-width: 0;
  width: 30px;
  height: 30px;
  top: 5px;
  right: 5px;
  border-radius: 20px;
  border-width: 2px;
  padding: 0;
  font-size: 10px;
  line-height: 20px;
  text-align: center;
  z-index: 9;
}

.bio .header-secured .profile-thumb .img-lazy-loader {
  height: 45px;
}

.bio .header-secured .profile-thumb .img-lazy-loader-empty,
.bio .bio-profile .img-lazy-loader.img-lazy-loader-empty,
.bio .list-tribe .list-tribe-item .img-lazy-loader-empty,
.bio .list-bios .item-bio .thumb-bio .img-lazy-loader-empty {
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('/assets/img/profile-male.jpg');
  background-size: contain;
}

.bio .header-secured .profile-thumb.is-pet .img-lazy-loader-empty,
.bio .bio-profile.is-pet .img-lazy-loader.img-lazy-loader-empty,
.bio .list-tribe .list-tribe-item .is-pet .img-lazy-loader-empty,
.bio .list-bios .item-bio .thumb-bio.is-pet .img-lazy-loader-empty {
  background-image: url('/assets/img/profile-pet.jpg');
}


.bio .thing-preview .thing-preview-img {
  position: relative;
  margin: 0;
  min-height: 700px;
}

.bio .thing-preview .thing-preview-img .img-lazy-loader .image {
  background-position: center top;
}

.bio .thing-preview-img .img-lazy-loader video {
  height: 420px;
  min-height: 0;
}

.bio .thing-preview-img .img-lazy-loader audio {
  height: auto;
  min-height: 100px;
}


.bio .memory-jar .file-uploader-list-item-preview .ico-preview {
  height: 60px;
}


.bio .thing-preview .file-uploader-list-item-preview .ico-preview {
  height: 80px;
}

.bio .list-bios .item-bio .thumb-bio .img-lazy-loader {
  width: 45px;
  height: 45px;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {

  .bio .modal-body-profile-picker .file-uploader-list-item,
  .bio .modal-body-thing-edit .file-uploader-list-item,
  .bio .modal-body-memory-edit .file-uploader-list-item,
  .bio .modal-thing-view .file-uploader-list-item,
  .bio .modal-memory-view .file-uploader-list-item {
    width: 20%;
  }

}
