/*
* Attention: Only place "very" generic styles into this file.
*/
$header-height: 60px;
$header-height-xs: 108px;


.header-secured {
  position: relative;
  position: -webkit-sticky;
  position: sticky;
  width: 100%;
  height: $header-height-xs;
  top:0;
  z-index: 1052;
  background: #162333;
  color: #fff;
}

.bio-build-ver {
  position: relative;
  z-index: 1;

  font-size: 0.7rem;
  color: #ccc;
  text-align: center;

  background: #162333;
}

@media (min-width: 768px) {
  .header-secured {
    height: $header-height;
    z-index: 1033;
  }
}



.bio-public main,
.main {
  position: relative;
  min-height: calc(100vh - #{$header-height});

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: flex-start;
  align-items: stretch;

  color: #333;
  background: #f8f8f8;
}

.bio-public main{
  align-content: center;
  align-items: center;
  justify-content: center;
}

.main .section {
  position: relative;
  width: auto;
  height: auto;
  overflow: visible;
  flex-grow: 1;
  transition: all 250ms linear;
}

.sidebar {
  position: fixed;
  width: 0;
  height: auto;
  top: $header-height;
  right: auto;
  bottom: 0;
  left: 0;
  background: #fff;
  transition: all 250ms linear;

  z-index: 1052;
  overflow: hidden;
  overflow-y: auto;

  box-shadow: inset -7px 0 9px -7px rgba(0, 0, 0, 0.7);
}

.sidebar-inset {
  float: right;
  width: 100px;
}

.bio.sidebar-left-active .sidebar {
  width: 100px;
}

.bio.sidebar-left-active .mobile-sidebar-overlay {
  position: fixed;
  width: auto;
  height: auto;
  top: $header-height;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1051;
  background: rgba(0, 0, 0, 0.8);
  transition: all 250ms linear;
}


/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {

  .sidebar {
    position: relative;
    width: 100px;
    min-height: 100%;
    height: auto;

    top: auto;
    right: auto;
    bottom: auto;
    left: auto;

    z-index: 1030;
  }

  .sidebar-inset {
    width: 100px;
  }

  .main.subnav-show .section {
    left: 200px; /* Sidebar + SubSidebar */
  }

  .bio.sidebar-left-active .mobile-sidebar-overlay {
    display: none;
  }

}
