@media (max-width: 1024px) {
  .modal.show .modal-dialog {
    top: 75px;
    padding-bottom: 75px;
  }
}

.modal-content{
  border-radius: 0;
}

.modal-dialog {
  color: #000;
}

.modal-body {
  position: relative;
}

.modal-body-form .control-label,
.modal-body-memory-edit .control-label,
.modal-body-thing-edit .control-label,
.modal-body-password-edit .control-label {
  font-size: 0.8rem;
  margin-bottom: 0.2rem;
  margin-left: 0.2rem;
}

.bio .modal-body .btn-gold{
  min-width: 90px;
}

.bio .modal-footer .btn {
  min-width: 90px;
}

.modal-body-form li + li {
  margin-top: 6px;
}

.modal-header.danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.bio .popover-header{
  background-color: $biojars-gold;
  color: #fff;
}

.modal-backdrop.modal-lightbox.show {
  opacity: 0.8;
}

.modal-lightbox .modal-dialog .modal-content {
  background: transparent;
  text-align: center;
}

.modal-lightbox .modal-dialog .modal-content .btn-close,
.modal-lightbox .modal-dialog .modal-content .btn-prev,
.modal-lightbox .modal-dialog .modal-content .btn-next {
  padding: 0;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  position: absolute;
  width: 50px;
  height: 50px;
  top: 0;
  right: 0;
  background: #fff;
  border-radius: 25px;
  opacity: 0.5;

  color: #000;
  font-size: 3rem;
  line-height: 25px;
}

.modal-lightbox .modal-dialog .modal-content .btn-prev,
.modal-lightbox .modal-dialog .modal-content .btn-next {
  top: 50%;
  margin-top: -25px;
}

.modal-lightbox .modal-dialog .modal-content .btn-prev {
  right: auto;
  left: 0;
}

.modal-lightbox .modal-dialog .modal-content .btn-close:hover,
.modal-lightbox .modal-dialog .modal-content .btn-prev:hover,
.modal-lightbox .modal-dialog .modal-content .btn-next:hover {
  opacity: 1;
}

.modal-lightbox .modal-dialog .modal-content .btn-close:focus,
.modal-lightbox .modal-dialog .modal-content .btn-prev:focus,
.modal-lightbox .modal-dialog .modal-content .btn-next:focus {
  outline: 0;
  border: 1px solid #c5af80;
  box-shadow: 0 0 5px 0 #c5af80;
}


.modal-lightbox .modal-dialog .modal-content .modal-body-lightbox {
  position: relative;
}

.modal-lightbox .modal-dialog .modal-content .lightbox-container {
  position: relative;
  width: auto;
  height: 480px;
  transition: all 0.3s ease-out;
}
.modal-lightbox .modal-dialog .modal-content .lightbox-container .file-title{
  position: absolute;
  bottom:-28px;
  left:0;
  right:0;
  z-index: 2;
  overflow: hidden;

  font-size: 1.2rem;
  background: #000;
  color: #FFF;
  white-space: nowrap;
  text-align: center;
}

.modal-body-plan-upgrade .user-plan-stats-box {
  margin: auto;
  max-width: 300px;
  padding: 20px;
}

.modal-lightbox .modal-dialog .modal-content .img-lazy-loader {
  position: absolute;
  width: auto;
  height: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

/* Bootstrap Animations (Until ngBootstrap does it) */
@keyframes modalBgFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}

@keyframes modalZoomIn {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.modal-backdrop {
  animation-name: modalBgFadeIn;
  animation-duration: 0.3s;
}

.modal > .modal-dialog {
  animation-name: modalZoomIn;
  animation-duration: 0.3s;
}
