.app-sitewide-search__result {
  display: flex;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: flex-start;

  .search-result__icon {
    width: 6rem;
    padding: 0.25rem 0.5rem;
  }

  .search-result__label {
    margin-left: 0.5rem;
    padding: 0.25rem 0.5rem;
  }
}

.app-sitewide-search {
  .input-group-text {
    border-right: 0;
    background: $white;
    color: #162333;
  }

  .dropdown-menu {
    min-width: 20rem;

    .dropdown-item {
      padding: 0;
    }
  }
}
