/*
* Attention: Only place "very" generic styles into this file.
*/

html, body {
  min-height: 100vh;
  height: 100vh;
}

body {
  font-family: 'Open Sans', sans-serif;
  background: #162333;
}

.loading-icon {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
  margin: auto;
  text-align: center;
}
path {
  stroke-dasharray: 2000; /* This value must be greater than or equal to the total length of the path */
  stroke-dashoffset: 2000;
  animation: draw 5s linear forwards;/* Set animation for 1 seconds */
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}


.bio {

}

.bio-public {
  color: #fff;
}

.bio-secured {
  color: #333;
}

.bio .x-small{
  font-size: 70%;
}


.bio h1,
.bio h2,
.bio h3 {
  font-family: 'Raleway', sans-serif;
}

.bio p:last-child {
  margin-bottom: 0;
}

.bio a {
  color: inherit;
  text-decoration: none;
}

.bio a:hover {
  text-decoration: underline;
}

.bio .card-bio {
  border-radius: 0;
  box-shadow: 1px 1px 3px 0px #CCC;
}

.bio .row-page-btns-space {
  margin: 20px auto;
}

.bio .row-page-btns-bio-view {
  border-top: 1px solid #333;
  border-bottom: 1px solid #333;
}

.bio .row-page-btns .btn-group {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  box-shadow: 1px 1px 3px 0px #CCC;
}


.bio .row-dash-btns .btn {
  padding-top: 25px;
  padding-bottom: 25px;
  border-radius: 0;
}

.bio .row-page-btns .btn-group .btn {
  max-width: 140px;
  border-radius: 0;
  cursor: pointer;
}

.bio .row-page-btns-things .btn-group {
  background: transparent;
  box-shadow: none;
  display: block;
  text-align: center;
}

.bio .row-page-btns-things .btn {
  position: relative;
  color: #1d344d;
  background: transparent;

  width: 109px;
  padding: 0;
  transform: scale(0.9);
}

.bio .row-page-btns-things .btn .text {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  margin-top: -1.5em;
  padding: 0 12px;
  font-size: 0.9rem;
  line-height: 1.2rem;
}

.bio .row-page-btns-things .btn:not(.active):hover,
.bio .row-page-btns-things .btn:hover,
.bio .row-page-btns-things .btn.active {
  background: transparent;
  color: #c5af80;
}

.bio .row-page-btns-bio-view .btn-group {
  display: block;
  box-shadow: none;
  margin: 10px auto 0;
  padding: 10px 0;
}

.bio .ico-gold{
  color: $biojars-gold;
}

.bio .btn-group-filters {
  border-bottom: 1px solid #DDD;
}

.bio .btn-group-filters .btn {
  border-radius: 0;
}

.bio .btn.dropdown-toggle {
  position: relative;
}

.bio .btn.dropdown-toggle .dd-has-filters {
  position: absolute;
  width: 20px;
  height: 20px;
  top: -5px;
  right: -5px;
  background: #1d344d;
  color: #fff;
  line-height: 20px;
  font-size: 10px;
  border-radius: 10px;
  overflow: hidden;
}

.bio .bg-cover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent url('/assets/img/bg-registration-v2.png') no-repeat center top;
  background-size: cover;
}

/* Hamburger Menu */
.bio .hamburger {
  display: inline-block;
  height: 40px;
  width: 42px;
  margin: 10px auto;
  padding: 2px 5px;

  background: #94a0a7;
  color: #fff;
  cursor: pointer;

  -webkit-transition: all .7s ease;
  -moz-transition: all .7s ease;
  -ms-transition: all .7s ease;
  -o-transition: all .7s ease;
  transition: all .7s ease;
}

.bio .hamburger .bar {
  display: block;
  height: 3px;
  width: 30px;
  background: #fff;
  margin: 6px auto;

  -webkit-transition: all .7s ease;
  -moz-transition: all .7s ease;
  -ms-transition: all .7s ease;
  -o-transition: all .7s ease;
  transition: all .7s ease;
}

.bio button.hamburger {
  background: transparent;
  border: none;
}

.bio .hamburger .text {
  font-size: 10px;
  line-height: 44px;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
}

.bio .hamburger:hover {
  text-decoration: none;
  color: #fff;
}

.bio .hamburger .bar-middle {
  margin: 0 auto;
}

.bio.sidebar-left-active .hamburger .bar-top {
  -webkit-transform: translateY(10px) rotateZ(45deg);
  -moz-transform: translateY(10px) rotateZ(45deg);
  -ms-transform: translateY(10px) rotateZ(45deg);
  -o-transform: translateY(10px) rotateZ(45deg);
  transform: translateY(10px) rotateZ(45deg);
}

.bio.sidebar-left-active .hamburger .bar-bottom {
  -webkit-transform: translateY(-8px) rotateZ(-45deg);
  -moz-transform: translateY(-8px) rotateZ(-45deg);
  -ms-transform: translateY(-8px) rotateZ(-45deg);
  -o-transform: translateY(-8px) rotateZ(-45deg);
  transform: translateY(-8px) rotateZ(-45deg);
}

.bio.sidebar-left-active .hamburger .bar-middle {
  width: 0;
}


/* Secured header */
.bio .header-secured .block-search {
  display: none; /* Not required yet*/
  position: absolute;
  top: 25px;
  left: 50px;
}

.block-search .input-group .btn {
  border: 1px solid #DDD;
  border-left: 0;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  background: transparent;
}

.block-search .input-group .form-control {
  border: 1px solid #DDD;
  border-right: 0;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background: transparent;
}

.block-search .input-group .form-control + .form-control {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.bio .header-secured .block-logo {
  position: absolute;
  width: 75px;
  height: 45px;
  top: 7px;
  right: auto;
  left: 20px;
  margin: auto;
  text-align: center;
}

.bio .block-sitewide-search{
  position: absolute;
  top: 70px;
  right:0;
  left:0;
  background: #162333;

  & .btn{
    color: inherit;
  }
}

.bio .header-secured .hamburger + .block-logo {
  left: 50px;
}

.bio .header-secured .block-logo img {
  display: block;
  width: auto;
  height: 100%;
}

.bio .header-secured .block-profile {
  position: absolute;
  top: 2px;
  right: 10px;
  bottom: 10px;
  left: auto;
}

.bio .header-secured .profile-name,
.bio .header-secured .profile-thumb {
  display: inline-block;
  vertical-align: middle;
  height: 45px;
  margin-top: 5px;
}

.bio .header-secured .profile-name {
  max-width: 120px;
  line-height: 45px;
  font-weight: 100;
  overflow: hidden;
}

.bio .header-secured .profile-thumb {
  position: relative;
  margin-left: 20px;
  width: 45px;
  overflow: hidden;
  border-radius: 24px;
}


/* Breadcrumbs */
.bio .breadcrumb-container {
  display: block;
  background: #e9ecef;
}

.bio .breadcrumb {
  border-radius: 0;
  margin: 0;
  padding: 0.25rem 0.5rem;
}


/* Page header */
.page-header {
  position: sticky;
  top: 60px;
  z-index: 1031;
  background: #f8f8f8;

  & .container {
    border-bottom: 1px solid #DADADA;
  }
}

.block-title, .block-search, .block-btns {
  position: relative;
  width: 100%;
  display: inline-block;
  vertical-align: middle;
}

.page-header .block + .block {
  margin-left: 15px;
}

.page-header .block button,
.page-header .block .btn {
  vertical-align: inherit;
}

.page-header .block-title h1 {
  font-size: 1.4rem;
  line-height: 1.3em;
  display: inline-block;
  vertical-align: inherit;
  margin: 0;
}


/* Memory Jars */
.bio .thing-preview .thing-preview-img,
.bio .memory-jar .memory-jar-preview {
  position: relative;
  display: block;
}

.bio .memory-jar .memory-jar-preview {
  height: 400px;
  margin: -1.25rem -1.25rem 0 -1.25rem;
}

.bio .thing-preview {
  border: 1px solid #c5af80;
}

.bio .thing-preview.empty {
  position: relative;
  min-height: 600px;
  background: #f2f2f2;
  color: #dbdbdb;
}

.bio .thing-preview.empty .thing-preview-text {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  text-align: center;
}

.bio .memory-jar .memory-jar-title {
  font-size: 18px;
  line-height: 18px;
  font-weight: bold;
  margin-top: 30px;
}

.bio .memory-jar .memory-jar-subtitle {
  font-size: 16px;
  margin: 0px auto;
  line-height: 16px;
  font-weight: 600;
}

.bio .memory-jar .memory-jar-description {
  margin: 1.0rem auto;
  color: #7a7a7a;
}


.bio .memories-list-container {
  min-height: 200px;
}

.bio .list-memories {
  list-style: none;
  margin: auto;
  padding: 0;
}

.bio .list-memories .item + .item,
.bio .list-memories .item:not(:first-child):last-child {
  margin-top: 25px;
  padding-top: 25px;
  border-top: 1px solid #ddd;
}

.bio .list-memories .item .thumb {
  position: relative;
  display: block;
  margin: auto;
  width: 100%;
  height: 100px;
  background-size: cover;
}

.bio .list-memories .item .thumb .ico-image-count {
  position: absolute;
  right: 3px;
  bottom: 3px;
  z-index: 2;
  color: #fff;
}

.bio .list-memories .item .thumb.empty {
  background: #f2f2f2;
  color: #dbdbdb;
}

.bio .list-memories .item .title {
  font-size: 1.0rem;
  font-weight: bold;
}

.bio .list-memories .item .tag {
  font-size: 0.85rem;
  color: $biojars-gold;
}

.bio .list-memories .item .description {
  font-size: 0.9rem;
}

.bio .list-memories .item .row-controls {
  font-size: 0.80rem;
  margin-top: 10px;
  line-height: 22px;
}

.bio .memory-jar .btn,
.bio .list-memories .item .row-controls .btn {
  min-width: 75px;
  font-size: 0.80rem;
  padding: 0.05rem 0.75rem;
}

/* Bio View / Edit */
.bio .bio-profile {
  position: relative;
  display: block;
  height: 400px;
}

.bio .bio-tribe {

}

.bio .bio-tribe .bio-tribe-title {

}

.bio .bio-tribe .list-tribe-active .list-tribe-item {
  float: none;
  width: auto;
  margin-left: 10px;
  margin-right: 10px;
}

.bio .bio-name {

}

.bio .bio-aka,
.bio .card-body .bio-aka {
  border: 0;
}

.bio .bio-profile .btn,
.bio .thing-preview .btn,
.bio .memory-jar-preview .btn {
  position: absolute;
  min-width: 0;
  width: 30px;
  height: 30px;
  top: 5px;
  right: 5px;
  border-radius: 20px;
  border-width: 2px;
  padding: 0;
  font-size: 10px;
  line-height: 20px;
  text-align: center;
  z-index: 2;
}

/* Thing Jar Banner */
.bio .banner-thing-jar {
  display: block;
  position: relative;
  height: 200px;
  overflow: hidden;
  background-color: #f2f2f2;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.bio .banner-thing-jar .banner-title {
  position: absolute;
  top: 0;
  left: 15px;
  background: #c5af80;
  color: #fff;
  padding: 50px 20px 20px;
}

.bio .banner-thing-jar .banner-title h1 {
  font-size: 1.2rem;
  line-height: 1.3em;
  margin: 0;
}

/* Bio/Tribester Search results */
.bio .list-tribesters,
.bio .list-bios {
  list-style: none;
  margin: 0;
  padding: 0;
}

.bio .list-bios {
  min-height: 100px;
}

.bio .list-tribesters .item,
.bio .list-bios .item {
  display: block;
  padding: 10px 0;
}

.bio .list-tribesters .item + .item,
.bio .list-bios .item + .item {
  border-top: 1px solid #EDEDED;
}

/* Tribe */
.bio .list-tribe {
  list-style: none;
  padding: 0;
  margin: 0;
}

.bio .list-tribe .list-tribe-item {

}

.bio .list-tribe .list-tribe-item .inset {
  position: relative;
  display: block;
  padding: 10px 20px;
  background: #fff;
  box-shadow: 0 0 5px 1px #DFDFDF;
}

.bio .list-tribe .list-tribe-item .list-tribe-item-preview {
  float: left;
  position: relative;
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 24px;
  cursor: pointer;
}


.bio .list-tribe .list-tribe-item .list-tribe-item-text {
  line-height: 1.3em;
  cursor: pointer;
}

.bio .list-tribe .list-tribe-item .list-tribe-item-btns .btn + .btn {
  margin-left: 0.5rem;
}

.bio .list-tribe-active,
.bio .list-tribe-legacy {
  margin-left: -10px;
  margin-right: -10px;
  padding: 5px 0;
  overflow: hidden;
}

.bio .list-tribe-active .list-tribe-item,
.bio .list-tribe-legacy .list-tribe-item {
  padding: 0 10px 20px 10px;
}

.bio .list-tribe-active .list-tribe-item .list-tribe-item-text,
.bio .list-tribe-legacy .list-tribe-item .list-tribe-item-text {
  line-height: 1.1em;
  padding: 6px 0;
}

.bio .list-guardians .list-tribe-item .list-tribe-item-text {
  padding-left: 70px;
}

.bio .list-tribe-legacy .list-tribe-item .list-tribe-item-preview {
  width: 100%;
  height: 340px;
  float: none;
  overflow: hidden;
  border-radius: 0;
  cursor: pointer;
}

.bio .list-tribe-legacy .list-tribe-item .list-tribe-item-text {
  background: #c5af80;
  color: #fff;
  font-size: 1.3rem;
  line-height: 1.5em;
  text-align: center;
  padding: 15px 0;
}

.bio .list-tribe-legacy .list-tribe-item .list-tribe-item-text .date {
  font-size: 1.0rem;
  line-height: 1.3em;
}

.bio .list-tribe-legacy .list-tribe-item .inset {
  padding: 10px;
}

.bio .list-bios .item-bio {

}

.bio .list-bios .item-bio .title {
  font-size: 1.0rem;
  margin: 0;
}

.bio .list-bios .item-bio .secondary-identifier{
  font-size: 0.9rem;
  color: $gray-500;
}

.bio .list-bios .item-bio .thumb-bio {
  position: relative;
  width: 45px;
  height: 45px;
  overflow: hidden;
  border-radius: 24px;
}

/* User Permissions */
.bio .user-permissions .icon-jar {
  position: relative;
  text-align: center;
}

.bio .user-permissions .icon-jar img {
  max-width: 100%;
  height: auto;
}

.bio .user-permissions .icon-jar .icon-jar-text {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  right: 0;
  margin-top: -20px;

  text-align: center;
  font-size: 0.9rem;
  font-weight: bold;
  line-height: 1.2;
}

.bio .user-permissions-toggle .btn-group-vertical {
  width: 100%;
}

.bio .user-permissions-toggle .btn {
  border: 1px solid #999;
  font-size: 0.9rem;
  color: #AAA;
}

.bio .user-permissions-toggle .btn.active {
  background: #AAA;
  color: #212529;
}

.bio .component-support-package .tutorials-list {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
}

.bio .component-support-package .tutorials-list .tutorial {
  display: block;
  vertical-align: top;
  text-align: center;
  padding: 20px;
}

.bio .component-support-package .tutorials-list .tutorial .content {
  position: relative;
}

.bio .component-support-package .tutorials-list .tutorial .tutorial-thumb {
  position: relative;
  display: block;
  height: 200px;
  background-size: initial;
  background: #EEE url('/assets/img/ico-is-video.png') no-repeat center;
  overflow: hidden;
}

.bio .component-support-package .tutorials-list .tutorial .tutorial-thumb img {
  position: absolute;
  display: block;
  top: 0;
  right: -999px;
  bottom: 0;
  left: -999px;
  height: 100%;
  width: auto;
  margin: auto;
}

.bio .component-support-package .tutorials-list .tutorial .tutorial-title {
  display: block;
  margin-top: 10px;
  font-size: 1.1rem;
  line-height: 1.3em;
  font-weight: bold;
}

.bio .component-support-package .text-legal-doc .card > .card-header button:hover,
.bio .component-support-package .text-legal-doc .card > .card-header button:focus {
  box-shadow: none;
  text-decoration: none;
}


/* Login / Registration */
.bio .card-bio-login {
  max-width: 430px;
  margin: 20px auto 0;
  float: none;
  color: #000;
  border-radius: 0;
  box-shadow: 0 0 10px 1px #000;
}

.bio .card-bio-login .card-body {
  padding: 1.25rem 1.25rem;
}

.bio .card-bio-login .card-body-title {
  margin-bottom: 20px;
}

.bio .card-bio-login .form-group a {
  cursor: pointer;
}

.bio .card-bio-login a:hover {
  text-decoration: underline;
}

.bio .card-bio-login .btn-lg {
  font-family: 'Raleway', sans-serif;
}

.bio .card-bio-login .btn-lg .spinner-border {
  vertical-align: initial;
}

/* Pagination */
.bio .page-item.active .page-link {
  background: #152332;
  color: #fff;
}

/* Dropdown Menus */
.bio .dropdown-menu{
  z-index: 1051;
}

@media (min-width: 768px) {
  .bio .dropdown-menu{
    z-index: 1034;
  }
}

.bio .dropdown-menu a:not([href]) {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none
}

.bio .dropdown-menu .ico,
.bio .dropdown-menu .ng-fa-icon {
  color: #DDD;
}

.bio .wards .dropdown-item.is-default {
  font-style: italic;
}


/* Alerts */
.alerts-container .alert-dismissible .close {
  top: 50%;
  margin-top: -25px;
}


/* Generic Helper Styles */
.bio .text-gold {
  color: #c5af80;
}

.bio .text-blue {
  color: #152332;
}

.bio .btn-gold {
  background: #c5af80;
  color: #fff;
  transition: all 250ms linear;
}

.bio .btn-gold:hover {
  background: $biojars-gold;
  text-decoration: none;
}

.bio .btn-gold-home {
  padding: 15px 25px;
  margin-bottom: 10px;
}

.bio .btn-blue {
  background: #152332;
  color: #fff;
  transition: all 250ms linear;
}

.bio .btn-blue:hover {
  background: #1d344d;
  text-decoration: none;
}

.bio .btn-white {
  background: #fff;
  color: #152332;
  transition: all 250ms linear;
}

.bio .btn-white.active {
  background: #152332;
  color: #fff;
}

.bio .btn-white:not(.active):hover {
  background: #EDEDED;
  text-decoration: none;
}


.bio .btn-hollow {
  color: #777;
  border: 1px solid #777;
  background: transparent;
  transition: all 250ms linear;
}

.bio .btn-hollow.active,
.bio .btn-hollow:hover {
  background: #EDEDED;
  border-color: #999;
  color: #999;
  text-decoration: none;
}

.bio .bg-gold {
  background-color: #c5af80;
}

.bio .bg-lightgold {
  background-color: #f3efe4;
}

.bio .bg-blue {
  background-color: #152332;
}

.bio .form-group .btn-group-lg > .btn:first-child {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.bio .form-group .btn-group-lg > .btn:last-child {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus,
.btn:focus, .btn.focus {
  outline: 0 none;
  box-shadow: 0 0 0 0.2rem rgba(197, 175, 128, 0.3);
}

.bio img.imageleft,
.bio img[style*="left"] {
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
}

.bio img.imageright,
.bio img[style*="right"] {
  float: right;
  margin-left: 20px;
  margin-bottom: 20px;
}


/* Bootstrap Extensions */
.bio .row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.bio .row-nopad {
  margin-left: 0;
  margin-right: 0;
}

.bio .row-nopad > div[class*='col-'] {
  padding-left: 0;
  padding-right: 0;
}

.bio .row-xxspad {
  margin-left: -1px;
  margin-right: -1px;
}

.bio .row-xxspad > div[class*='col-'] {
  padding-left: 1px;
  padding-right: 1px;
}

.bio .row-xspad {
  margin-left: -5px;
  margin-right: -5px;
}

.bio .row-xspad > div[class*='col-'] {
  padding-left: 5px;
  padding-right: 5px;
}

.bio .row-lgpad {
  margin-left: -15px;
  margin-right: -15px;
}

.bio .row-lgpad > div[class*='col-'] {
  padding-left: 15px;
  padding-right: 15px;
}

/* for xl checkboxes */
.form-control-lg .custom-control-label::before,
.form-control-lg .custom-control-label::after {
  top: .3rem;
  width: 1.25rem;
  height: 1.25rem;
}

.checkbox-xl .custom-control-label::before,
.checkbox-xl .custom-control-label::after {
  top: 0.5rem;
  width: 1.85rem;
  height: 1.85rem;
}

.checkbox-xl input:checked ~ .custom-control-label::before {
  border-color: $biojars-gold;
  background-color: $biojars-gold;
}

.checkbox-xl .custom-control-label {
  padding-top: 0px;
  padding-left: 20px;
}

.modal-header.modal-memory-view .btn-sm,
.modal-header.modal-password-view .btn-sm {
  font-size: 0.7rem;
}

.password-dataset{
  list-style: none;
  margin:0;
  padding:0;
}

.password-data{
  margin-top: 1rem;
}

.password-data__label{
  font-size: 0.8rem;
  line-height: 1.2em;
  font-weight: bold;
  margin:0;
}
.password-data__value{
  margin-top: 6px;
}
.password-data__value .btn{
  vertical-align: text-bottom;
  padding: 0;
  margin-left: 15px;
}
.password-data{
  margin-top: 1rem;
}


.accordion .collapse {
  display: block;
  overflow: hidden;
  height: auto;
  max-height: 0px;
  transition: max-height 0.3s ease-out;
}

.accordion .collapse.show {
  overflow-y: auto;
  max-height: 400px;
  transition: max-height 0.3s ease-out;
}

.popover{
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.5);
  .popover-body{
    white-space: pre-wrap;
  }
}


/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {

  /* Login / registration */
  .bio .card-bio-login .card-body {
    padding: 1.25rem 3.25rem;
  }

  /* Secured header */
  .bio .header-secured .block-search {
    display: none; /* Not required yet*/
    position: absolute;
    top: 25px;
    left: 50px;
  }

  .bio .header-secured .block-logo {
    width: 64px;
    height: 50px;
    top: 5px;
    right: auto;
    left: 10px;
  }

  .bio .header-secured .hamburger + .block-logo {
    left: 10px;
  }

  .bio .header-secured .block-logo img {
    margin-left: 0;
  }

  .bio .block-sitewide-search{
    position: absolute;
    max-width: 600px;
    top: 8px;
    right: 250px;
    left:100px;
    margin-left: auto;
  }

  .bio .header-secured .block-profile {
    position: absolute;
    top: 10px;
    right: 50px;
    bottom: 10px;
    left: auto;
    cursor: pointer;
  }

  .bio .header-secured .profile-name,
  .bio .header-secured .profile-thumb {
    margin-top: -3px;
  }

  /* Breadcrumbs */
  .bio .breadcrumb-container {
    display: block;
    background: #e9ecef;
  }

  .bio .breadcrumb {
    padding: 0.75rem 1rem;
  }


  /* Page header */
  .page-header .block-title h1 {
    font-size: 2.4rem;
    line-height: 1.3em;
  }


  .bio .row-page-btns-space {
    margin: 40px auto 40px auto;
  }

  .bio .row-page-btns .btn-group .btn {
    min-width: 118px;
  }

  .bio .row-page-btns-things .btn-group {
    display: flex;
  }

  .bio .row-page-btns-things .btn {
    width: auto;
    transform: none;
  }

  .bio .list-tribe-active .list-tribe-item {
    float: left;
    width: 50%;
  }

  .bio .list-tribe-active .list-tribe-item .list-tribe-item-text {
    height: 75px;
  }

  .bio .list-guardians .list-tribe-item .list-tribe-item-text{
    height: 105px;
  }

  .bio .list-tribe-legacy .list-tribe-item {
    float: left;
    width: 33.333%;
  }

  .bio .component-support-package .tutorials-list .tutorial {
    display: inline-block;
    vertical-align: top;
    width: 30%;
  }

  .bio .component-support-package .text-legal-doc .card > .card-header button::after,
  .bio .component-support-package .text-legal-doc .card > .card-header button.collapsed::after {
    right: auto;
  }

  /* Memory Password List */
  .bio .list-memories-passwords {
    margin-left: -10px;
    margin-right: -10px;
    overflow: hidden;
  }

  .bio .list-memories-passwords .item-memory {
    position: relative;
    display: inline-block;
    width: 50%;
    border: 0;
    padding: 10px;
  }

  .bio .list-memories-passwords .item + .item,
  .bio .list-memories-passwords .item:not(:first-child):last-child {
    border: 0;
    margin-top: 0;
  }

  .bio .list-memories-passwords .item-memory .item-inset {
    border: 1px solid #DDD;
    padding: 10px;
  }

  .bio .list-memories-passwords .item .title {
    height: 39px;
    overflow: hidden;
  }

  .password-dataset .password-data{
    float:left;
    width: 50%;
  }

  .bio .list-tribe .list-tribe-item .list-tribe-item-message{
    float: left;
    min-width: calc(100% - 220px);
  }

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}


.bio .card-body h2,
.bio .page-steps h2 {
  font-weight: 600;
  border-bottom: 1px solid #c5af80;
  line-height: 35px;
  font-size: 1.4rem;
}

.bio button.max300-center {
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.bio .list-subscription-details {
  list-style: none;
  padding: 0;
}

.bio .list-subscription-details__logo{
  & img {
    display: block;
    max-width: 130px;
    height: auto;
    margin: auto;
  }
}

.bio .list-subscription-details h3 {
  text-align: center;
  font-size: 1.2rem;
  font-weight: 600;
}

.bio .list-subscription-details .jar-bg {
  min-height: 100px;
  padding-top: 100px;
  background-image: url('/assets/img/jar-off.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.bio .list-subscription-details h4 {
  text-align: center;
  /* font-size: 1.1rem; */
  font-size: 1.5rem;
  /* font-weight: 600; */
  font-weight: bold;
  color: #c5af80;
}

.bio .list-subscription-details .item {
  list-style: none;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.bio .list-subscription-details .item div {
  text-align: center;
}


.bio .component-subscription-package .sponsors-overlay {
  position: absolute;
  /*display: none;*/
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  cursor: pointer;
}



@media (min-width: 768px) {

  .bio .component-subscription-package .is-highlight .card {
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
    box-shadow: 0px 4px 6px 1px rgba(0, 0, 0, 0.33);
    margin-top: 0px;
  }

}


.bio .component-subscription-package #card-element {
  border: 1px solid #ced4da;
  padding: 4px;
  margin: 56px 4px;
}

.bio .component-subscription-package a {
  cursor: pointer;
}

.bio p.centered,
.bio .text-centered {
  text-align: center;
}

.bio .component-subscription-package .submit-btn {
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: 2px;
  padding: 14px;
}

.card-bio.is-selected {
  border: 6px solid #1d344d;
}

.bio a.text-gold {
  color: #c5af80;
  cursor: pointer;
}

.bio a.text-gold:hover {
  color: #c5af80;
  font-weight: bold;
}

.bio a.remove-link {
  line-height: 55px;
}

.bio .btn-in-text-box.input-group > .input-group-prepend > .btn,
.bio .btn-in-text-box.input-group > .input-group-append > .btn {
  border-radius: 0 0.30em 0.30em 0;
  background-color: #162333;
  color: #FFF;
}

.bio .btn-in-text-box.input-group > .input-group-prepend > .btn {
  border-radius: 0.30em 0 0 0.30em;
}

.bio .btn-in-text-box.input-group > .input-group-prepend > .btn:hover,
.bio .btn-in-text-box.input-group > .input-group-append > .btn:hover {
  background-color: #162333;
  box-shadow: 0 0 0 0.2rem rgba(197, 175, 128, 0.5);
  color: #c5af80;
}

.bio .btn-in-text-box.input-group > .input-group-prepend > .sort-handle,
.bio .btn-in-text-box.input-group > .input-group-append > .sort-handle {
  width: 24px;
  padding-left: 0;
  padding-right: 0;
  background: #EEE;
  color: #AAA;
  cursor: move;
  border: 1px solid #ced4da;
}

.bio .btn-in-text-box.input-group > .input-group-prepend > .sort-handle:hover,
.bio .btn-in-text-box.input-group > .input-group-append > .sort-handle:hover {
  background: #AAA;
  color: #666;
  box-shadow: none;
}

.bio .bio-name .ico,
.bio .bio-edit-name .ico {
  width: 0.6em;
  height: 0.6em;
  color: #c5af80;
  vertical-align: top;
  margin-right: -0.4em;
  margin-left: -0.4em;
}

.bio .bio-edit-container .bio-options-menu a {
  cursor: pointer;
}

.bio .bio-edit-container .bio-options-menu a:hover {
  text-decoration: none;
}

.bio .bio-edit-container .form-group ul {
  padding: 0;
}

.bio .bio-edit-container .control-label {
  font-weight: 600;
}

.bio .bio-edit-container .common-address-container .control-label {
  display: none;
}

.bio .bio-edit-container .bio-summary {
  border-top: 1px solid #c5af80;
}

.bio .bio-edit-container .bio-summary ul {
  padding-left: 10px;
}

.bio .bio-edit-container .bio-summary ul li {
  list-style: none;
  border-bottom: 1px solid #c0c0c0;
  padding-top: 12px;
}

.bio .bio-edit-container .bio-summary ul li label {
  color: #c0c0c0;
  font-size: 14px;
  margin-bottom: 0;
}

.bio .bio-edit-container .bio-summary ul li p {
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 20px;
}

.bio .bio-edit-container .addresses-box {

}

.bio .bio-edit-container .addresses-box .remove-link {
  color: #162333;
  clear: both;
  width: 100%;
  cursor: pointer;
}

.bio .bio-edit-container .addresses-box .remove-link:hover {
  font-weight: bold;
  text-decoration: none;
}


/* FAQ Style */
.image-title-banner {
  display: block;
  position: relative;
}

.image-title-banner .banner-title {
  color: #c3af7d;
  font-size: 34px;
  line-height: 39px;
  font-weight: 600;
}

.image-title-banner .slide {
  padding: 25px;
  background-image: url('/assets/img/faq-header.png');
  background-position-x: right;
  text-align: center;
}

.image-title-banner p {
  font-size: 18px;
  line-height: 23px;
  margin: 0;
  padding: 0;
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 575px) {

  .image-title-banner .slide {
    background-image: url('/assets/img/faq-header.png');
    background-position-x: right;
    padding-left: 4px;
    padding-right: 4px;
  }

  .image-title-banner .slide .banner-title {
    font-size: 16px;
    line-height: 16px
  }

}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

  .image-title-banner .slide {
    background-image: url('/assets/img/faq-header.png');
    background-position-x: right;
  }

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {

  .image-title-banner {
    height: 200px;
  }

  .image-title-banner .slide {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url('/assets/img/faq-header.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 50px;
    padding-top: 70px;
  }

}


.bio .component-subscription-package .paypal-payment-col p,
.bio .component-subscription-package .stripe-payment-col p {
  font-size: 1rem;
  margin-bottom: 32px;
}

.paypal-payment-col p {
  padding-left: 45px;
}

//.stripe-payment-col {
//  border-left: 1px solid #dee2e6;
//  border-top: 0;
//  padding-top: 0;
//}

@media (max-width: 768px) {
  .stripe-payment-col {
    border-left: 0;
    border-top: 1px solid #dee2e6;
    padding-top: 35px;
  }
}
